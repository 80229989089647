import React from "react";

import "./ReleasedEpisode.scss"
import {Link} from "react-router-dom";
import Text from "../../atoms/Text/Text";
import MonsterCircle from "../../atoms/MonsterCircle/MonsterCircle";
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";

const ReleasedEpisode = (props) => {
    const { episode } = props
    return (
        <Link
            key={ episode.id }
            className="released-episode"
            to={ episode.url }
        >
            <MonsterCircle src={episode.imageLink} size={120} color="card" />
            <Text type="h2" align="center">{ episode.name }</Text>
            <SVGIcon name="arrow-right" size={16} />
        </Link>
    )
};

export default ReleasedEpisode;
