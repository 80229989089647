import React, { Component } from 'react';
import "./FilterPill.scss"

import { Link } from 'react-router-dom';
import Text from "../Text/Text";
import SVGIcon from "../SVGIcon/SVGIcon";
import {paramCase} from "change-case";

class FilterPill extends Component {
    render () {
        const { children, color, route, activeFilter, icon, noCircle, baseRoute } = this.props
        if (!children) return

        const isActive = activeFilter && activeFilter.toLowerCase() === children.toLowerCase()
        const classes = [
            'filter-pill',
            isActive ? 'active' : '',
            color,
        ].join(' ')

        const linkRoute = isActive ? baseRoute : route

        return (
            <Link className={classes} to={linkRoute} id={ paramCase(children) }>
                { (!icon && !noCircle) && <div className="circle" />}
                <Text type="small" weight={400} style={{ letterSpacing: 0.6 }}>{ children.toUpperCase() }</Text>
                { icon && <SVGIcon name={icon} size={12} />}
            </Link>
        )
    }
}

FilterPill.propTypes = {
};

export default FilterPill