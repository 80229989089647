import React from "react";
import "./RandomLink.scss"
import Text from "../Text/Text";
import SVGIcon from "../SVGIcon/SVGIcon";
import {Link} from "react-router-dom";
const {DateTime} = require("luxon");

const RandomLink = (props) => {
    const { link } = props
    const colors = ["cat1", "cat2", "cat3", "cat4", "cat5", "cat6", "cat7"]
    const color = colors[Math.floor(Math.random() * colors.length)]

    const classes = [
        "random-link",
        color,
    ].join(" ")

    return (
        <Link
            to={link.url}
            className={classes}
            key={link.url}
        >
            <SVGIcon name="arrow-right" size={24} />
            <Text type="h2">{ link.caption }</Text>
        </Link>
    )
};

export default RandomLink;
