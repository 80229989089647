import React from 'react';
import "./SideMenu.scss"
import {Link} from "react-router-dom";
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";
import urlUtil from "../../../utils/urlUtil";
import {AnimatePresence, motion} from "framer-motion";
import Text from "../../atoms/Text/Text";
import paintersDataUtil from "../../../utils/paintersDataUtil";
import generalUtil from "../../../utils/generalUtil";
import MonsterHead from "../../atoms/MonsterHead/MonsterHead";
import episodesDataUtil from "../../../utils/episodesDataUtil";

function SideMenu (props) {
    const { toggleMenu, isMenuOpen, navigateTo } = props
    const { slug } = urlUtil.getURLParams()
    let name = slug ? paintersDataUtil.getBySlug(slug).name : null

    function clickLink(url) {
        if (generalUtil.isLeavingPage(url)) {
            navigateTo(url)
        }

        toggleMenu()
    }

    const sideMenuLinks = episodesDataUtil.getReleasedForSeason().map(ep => {
        return { copy: ep.name, url: urlUtil.createURL("bio", { slug: ep.param }), slug: ep.param }
    })
    sideMenuLinks[0].slug = "painter"
    sideMenuLinks[0].url = urlUtil.createURL("timeline", { type: "art" })

    return (
        <AnimatePresence>
            { isMenuOpen && (
                <motion.div
                    className="side-menu"
                    initial={{ right: -250, opacity: 0}}
                    animate={{ right: 0, opacity: 1}}
                    exit={{ right: -250, opacity: 0}}
                    transition={{ ease: "easeInOut", duration: 0.3 }}
                >
                    <div
                        onClick={() => toggleMenu()}
                        className="close-btn"
                    >
                        <SVGIcon color="link" name="close" />

                    </div>

                    <div className="links-wrapper">
                        <div className="top-link">
                            <Link
                                onClick={() => toggleMenu()}
                                to="/"
                                className="side-link"
                            >
                                <Text type="h1" weight={300} color="link">Home</Text>
                            </Link>
                        </div>

                        <hr />

                        { sideMenuLinks.map(l => (
                            <div
                                onClick={() => clickLink(l.url)}
                                to={l.url}
                                className="side-link"
                                key={l.copy}
                            >
                                <MonsterHead slug={l.slug} size={28}/>
                                <Text type="h1" weight={300} color="link">{ l.copy }</Text>
                            </div>
                        )) }
                    </div>
                </motion.div>
            )}

            { isMenuOpen && (
                <motion.div
                    onClick={() => toggleMenu()}
                    key="backdrop"
                    className="menu-backdrop"
                    initial={{ opacity: 0}}
                    animate={{ opacity: 1}}
                    exit={{ opacity: 0}}
                    transition={{ ease: "easeInOut", duration: 0.3 }}
                />
            )}
        </AnimatePresence>
    )
}

SideMenu.propTypes = {
};

export default generalUtil.withNavParams(SideMenu)