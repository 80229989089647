import React from "react";
import "./NextEpisode.scss"
import Text from "../Text/Text";
const {DateTime} = require("luxon");

const NextEpisode = (props) => {
    const { episode } = props

    return (
        <div className="next-episode">
            <Text type="h2">{ episode.name }</Text>
            <Text className="release-date">{ DateTime.fromJSDate(episode.rDate).toFormat("MMM-dd") }</Text>
        </div>
    )
};

export default NextEpisode;
