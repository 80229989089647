const PAGES = {
    HOME: "/",
    TIMELINE: "/timeline",
    BIO: "/bio",
}

const QUERY_PARAMS = {
    TIMELINE: {
        BUBBLE: "bubble",
        SCROLL: "scroll",
    },
    BIO: {
        YEAR: "year",
        ARTWORK: "artwork",
    }
}

const urlUtil = {
    getCurrentURL: () => {
        return window.location.href
    },

    getURLParams: (location = window.location) => {
        const query = new URLSearchParams(location.search)
        let URLParams = {}

        if (location.pathname.includes(PAGES.TIMELINE)) {
            URLParams = {
                type: location.pathname.split("/")[2],
                filter: decodeURI(location.hash.substring(1)),
                bubble: query.get("bubble"),
                scroll: query.get("scroll"),
            }
        }

        if (location.pathname.includes(PAGES.BIO)) {
            URLParams = {
                slug: location.pathname.split("/")[2],
                filter: decodeURI(location.hash.substring(1)),
                year: query.get("year"),
                artwork: query.get("artwork"),
            }
        }

        Object.keys(URLParams).forEach(p => {
            if (!URLParams[p]) delete URLParams[p]
        })

        return URLParams
    },

    getQueryString: () => {

    },

    createURL: (path, params = {}, options = {}) => {
        const { keepOthers } = options
        let pathname = `/${path}`
        let hash
        let queryObj = {}
        let queryParams
        const currentParams = urlUtil.getURLParams()
        let combinedParams = { ...params }

        if (keepOthers) {
            combinedParams = {
                ...currentParams,
                ...combinedParams
            }
        }

        // timeline - /timeline/painters?bubble|scroll#filter
        if (pathname === PAGES.TIMELINE) {
            pathname = `${pathname}/${params.type}`
            queryParams = Object.values(QUERY_PARAMS.TIMELINE)
            if (combinedParams.filter) hash = combinedParams.filter
        }

        // bio - /bio/leonardo-da-vinci?year|artwork#filter
        if (pathname === PAGES.BIO) {
            pathname = `${pathname}/${combinedParams.slug}`
            queryParams = Object.values(QUERY_PARAMS.BIO)
            if (combinedParams.filter) hash = combinedParams.filter
        }

        queryParams.forEach(qp => {
            queryObj[qp] = combinedParams[qp]
            if (!queryObj[qp]) delete queryObj[qp]
        })
        const queryString = new URLSearchParams(queryObj).toString()

        let url = pathname
        url = queryString ? `${url}?${queryString}` : url
        url = hash ? `${url}#${hash}` : url

        return url
    },
}

export default urlUtil