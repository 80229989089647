import React from 'react';
import "./TimelineCanvas.scss"
import ReactFlow from 'reactflow';
import 'reactflow/dist/style.css';

import TimelinePill from "../../atoms/TimelinePill/TimelinePill";
import timelineUtil from "../../../utils/timelineUtil";
import urlUtil from "../../../utils/urlUtil";
import { TimelineLines, TimelineYears } from "../TimelineGrid/TimelineGrid";

const nodeTypes = { TimelinePill };

function TimelineCanvas(props) {
    const { start, end, data } = props
    const { bubble } = urlUtil.getURLParams()

    const { dataWithPositions, numOfRows } = timelineUtil.calculatePositions(data, start, end)
    const { timelineWidth, timelineHeight } = timelineUtil.getSize(start, end, numOfRows)

    const nodes = dataWithPositions
        // sort by name to preserve order of elements and keep animations
        .sort((a,b) => {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
        .map((p, i) => {
            return {
                id: p.slug,
                position: { x: p.left, y: p.top },
                data: { key: p.slug, person: p, timelineWidth, timelineHeight },
                type: 'TimelinePill',
                zIndex: bubble === p.slug ? 5 : 1,
            }
        });

    const classes = [
        "timeline-canvas",
    ].join(" ")

    return (
        <div className={classes}>
            <ReactFlow
                nodeTypes={nodeTypes}
                nodes={nodes}
                maxZoom={4.5}
                minZoom={0.3}
                onNodeClick={() => {}}
                zoomOnScroll={false}
                zoomOnDoubleClick={false}
                defaultViewport={{ x: -300, y: -1700, zoom: 0.8 }}
                translateExtent={[[0, 0], [timelineWidth, timelineHeight]]}

                elementsSelectable={false}
                nodesDraggable={false}
                nodesFocusable={false}
                nodesConnectable={false}
                edgesFocusable={false}
                selectionOnDrag={false}
                selectNodesOnDrag={false}
                elevateNodesOnSelect={false}
                connectOnClick={false}
            />

            <TimelineLines
                start={start}
                end={end}
                timelineHeight={timelineHeight}
                timelineWidth={timelineWidth}
            />
            <TimelineYears
                start={start}
                end={end}
                timelineHeight={timelineHeight}
                timelineWidth={timelineWidth}
            />
        </div>
    )
}

TimelineCanvas.propTypes = {

};

export default TimelineCanvas