import React, { Component } from 'react';
import Text from "../../atoms/Text/Text";
import "./BioCard.scss"
import {Link} from "react-router-dom";
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";
import {capitalCase} from "change-case";
import {EVENT_LINK_TYPES} from "../../../constants";
import painterEventsDataUtil from "../../../utils/painterEventsDataUtil";
import urlUtil from "../../../utils/urlUtil";
import {motion, AnimatePresence} from "framer-motion";
import bioTransitionsUtil from "../../pages/Biographic/bioTransitionsUtil";
import episodesDataUtil from "../../../utils/episodesDataUtil";

const cardAnimation = {
    initial: { opacity: 0, bottom: -200 },
    animate: { opacity: 1, bottom: 0 },
    exit: { opacity: 0, bottom: -200 },
    transition: { ease: "easeIn", duration: 0.3 },
}

const cardTextAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { ease: "easeIn", duration: 0.1 },
}

class BioCard extends Component {
    renderYearCard() {
        const { bioData } = this.props
        const { year, slug, filter } = urlUtil.getURLParams()

        let yearData
        let prevYear
        let nextYear
        let yearEvents

        const eventsByYear = filter ? painterEventsDataUtil.getByFilter(slug, filter) : bioData.eventsByYear

        eventsByYear.forEach((y, i, arr) => {
            if (y.year == year) {
                yearData = y
                prevYear = arr[i-1] ? arr[i-1].year : null
                nextYear = arr[i+1] ? arr[i+1].year : null
                yearEvents = yearData.events
            }

        })

        const events = yearEvents
            .sort((a, b) => a.position - b.position)
            .map((e, i) => {
                const hasLink = !!e.linkType
                if (hasLink) {
                    let link
                    if (e.linkType === EVENT_LINK_TYPES.ARTWORK) {
                        link = urlUtil.createURL('bio', { artwork: e.linkParam1 }, { keepOthers: true })
                    }
                    if (e.linkType === EVENT_LINK_TYPES.BIO && episodesDataUtil.isEpReleased(e.linkParam1)) {
                        link = urlUtil.createURL('bio', { slug: e.linkParam1 })
                    }
                    const eventText = e.event.split(e.linkText)
                    if (link) {
                        return (
                            <div className="event" key={i}>
                                <div className={`dot ${bioData.categories[e.category]}`} />
                                <Text>
                                    {eventText[0]}
                                    <Link to={link}>
                                        <Text type="link" span>{ e.linkText }</Text>
                                    </Link>
                                    {eventText[1]}
                                </Text>
                            </div>
                        )
                    }
                }

                return (
                    <div className="event" key={i}>
                        <div className={`dot ${bioData.categories[e.category]}`} />
                        <Text>{ e.event }</Text>
                    </div>
                )
            })

        const bioRoute = urlUtil.createURL('bio', { slug })

        const networkedEvent = painterEventsDataUtil.getNetworkedEvent(year)
        if (networkedEvent && networkedEvent.slug !== slug && episodesDataUtil.isEpReleased(networkedEvent.slug)) {
            let eventText = networkedEvent.event.replace(networkedEvent.name, "").split(networkedEvent.linkText)
            const link = urlUtil.createURL('bio', { artwork: networkedEvent.linkParam1 }, { keepOthers: true })
            events.push((
                <div className="networked-event" key={networkedEvent.slug}>
                    <hr />
                        <div className="event">
                            <div className="dot" />
                            <Text>
                                <Link to={urlUtil.createURL('bio', { slug: networkedEvent.slug, year: networkedEvent.year })}>
                                    <Text type="link" span>{ `${networkedEvent.name} ` }</Text>
                                </Link>
                                {eventText[0]}
                                {eventText[1] && (
                                    <Link to={link}>
                                        <Text type="link" span>{ networkedEvent.linkText }</Text>
                                    </Link>
                                )}
                                {eventText[1]}
                            </Text>
                        </div>
                </div>
            ))
        }

        let nextRoute
        if (nextYear) nextRoute = urlUtil.createURL('bio', { year: nextYear }, { keepOthers: true })
        if (filter && !nextYear) nextRoute = urlUtil.createURL('bio', { year: undefined }, { keepOthers: true })

        let prevRoute
        if (prevYear) prevRoute = urlUtil.createURL('bio', { year: prevYear }, { keepOthers: true })
        if (filter && !prevYear) prevRoute = urlUtil.createURL('bio', { year: undefined }, { keepOthers: true })

        return (
            <motion.div
                className="bio-card"
                id="bio-card"
                { ...cardAnimation }
            >
                <div className="card-header">
                    <Link to={bioRoute}><SVGIcon size={24} color="link" name="close" /></Link>
                </div>
                <AnimatePresence mode="wait">
                    <motion.div
                        key={`${slug}-${year}`}
                        className="events"
                        { ...cardTextAnimation }
                    >
                            { events }
                    </motion.div>
                </AnimatePresence>
                <div className="card-footer">
                    { prevRoute ? (
                        <Link to={prevRoute} className="chevron-left-link"><SVGIcon size={24} color="link" name="chevron-left" /></Link>
                    ) : (
                        <div style={{ width: 31 }}/>
                    )}
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={`${slug}-${year}-heading`}
                            { ...cardTextAnimation }
                        >
                            <Text type="h1">{year} <span>({yearData.age} years old)</span></Text>
                        </motion.div>
                    </AnimatePresence>
                    { nextRoute && <Link to={nextRoute} className="chevron-right-link"><SVGIcon size={24} color="link" name="chevron-right" /></Link>}
                </div>
            </motion.div>
        )
    }

    renderFilterCard() {
        const { bioData } = this.props
        const { slug, filter } = urlUtil.getURLParams()

        const filterEvents = painterEventsDataUtil.getByFilter(slug, filter)

        const bioRoute = urlUtil.createURL('bio', { slug })
        const cat = bioData.categories[capitalCase(filter)] || bioData.categories[filter]
        const hasText = !!bioData[`${cat}Text`]
        if (!hasText) return;

        const nextYearRoute = filterEvents.length > 0 && urlUtil.createURL('bio', { year: filterEvents[0].year }, { keepOthers: true })

        const filterText = hasText ? bioData[`${cat}Text`] : ''
        return (
            <motion.div
                className="bio-card filter"
                id="bio-card"
                { ...cardAnimation }
            >
                <div className="card-header">
                    <Link to={bioRoute}><SVGIcon size={24} color="link" name="close" /></Link>
                </div>
                <AnimatePresence mode="wait">
                    <motion.div
                        className="events"
                        key={`${slug}-${filter}-text`}
                        { ...cardTextAnimation }
                    >
                        <Text>{ filterText }</Text>
                    </motion.div>
                </AnimatePresence>
                <div className="card-footer">
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={`${slug}-${filter}-heading`}
                            { ...cardTextAnimation }
                        >
                            <Text type="h1">{ capitalCase(filter) }</Text>
                        </motion.div>
                    </AnimatePresence>
                    { nextYearRoute && <Link className="chevron-right-link" to={nextYearRoute}><SVGIcon size={21} color="link" name="chevron-right" /></Link>}
                </div>
            </motion.div>
        )
    }

    renderGeneralCard() {
        const { bioData } = this.props
        const { slug } = urlUtil.getURLParams()
        const bioRoute = urlUtil.createURL('bio', { slug })
        const nationalityLinks = []
        bioData.nationality.forEach((n, i) => {
            if (i !== 0) {
                nationalityLinks.push(<span key={i}>  |  </span>)
            }
            nationalityLinks.push(
                <Link
                    key={n}
                    to={urlUtil.createURL('timeline', { type: 'art', filter: n, scroll: bioData.name }, { keepOthers: true })}
                >
                    {n}
                </Link>
            )
        })
        const movementLinks = []
        bioData.movements.forEach((m, i) => {
            if (i !== 0) {
                movementLinks.push(<span key={i}>  |  </span>)
            }
            movementLinks.push(
                <Link
                    key={m}
                    to={urlUtil.createURL('timeline', { type: 'art', filter: m, scroll: bioData.name }, { keepOthers: true })}
                >
                    {m}
                </Link>
            )
        })

        return (
            <motion.div
                className="bio-card general"
                id="bio-card"
                { ...cardAnimation }
            >
                <div className="card-header">
                    <Link to={bioRoute}><SVGIcon size={24} color="link" name="close" /></Link>
                </div>
                <AnimatePresence mode="wait">
                    <motion.div
                        className="events"
                        key={`${bioData.slug}-text`}
                        { ...cardTextAnimation }
                    >
                        <Text>{ bioData.intro }</Text>

                        <div className="general-links">
                            <SVGIcon color="link" name="flag" size={14} />
                            <Text>{ nationalityLinks }</Text>
                        </div>
                        <div className="general-links">
                            <SVGIcon color="link" name="paint-brush" size={14} />
                            <Text>{ movementLinks }</Text>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </motion.div>
        )
    }

    render () {
        const { year, filter } = urlUtil.getURLParams()

        return (
            <AnimatePresence>
                { year && this.renderYearCard() }
                { !year && filter === "Intro" && this.renderGeneralCard() }
                { filter && !year && this.renderFilterCard() }
            </AnimatePresence>
        )
    }
}

BioCard.propTypes = {
};

export default BioCard