import React, {useState, useEffect} from "react";
import "./WalletAddress.scss"
import Text from "../Text/Text";
import SVGIcon from "../SVGIcon/SVGIcon";
import {WALLETS} from "../../../constants";
import copy from 'copy-to-clipboard';


const WalletAddress = (props) => {
    const [isCopying, setIsCopying] = useState(false);
    const { coin } = props
    const hiddenWallet = `${ WALLETS[coin].wallet.slice(0, 6) }...${ WALLETS[coin].wallet.slice(-6) }`

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsCopying(false)
            clearTimeout(timeout)
        }, 500)
    }, [isCopying])

    const classes = [
        "wallet-address",
        isCopying ? "is-copying" : ""
    ].join(" ")

    return (
        <div
            className={classes}
            onClick={() => {
                setIsCopying(true)
                copy(WALLETS[coin].wallet)
            }}
        >
            <div className="coin-name">
                <SVGIcon name={WALLETS[coin].coin.toLowerCase()} size={16} />
                <Text font weight={200}>
                    <Text span font weight={500}>{ WALLETS[coin].coin } </Text>
                    { coin }
                </Text>
            </div>

            <div className="copy-address">
                <Text span font weight={500}> { hiddenWallet }</Text>
                <SVGIcon
                    name={isCopying ? "check-circle" : "document-duplicate"}
                    size={14}
                />
            </div>
        </div>
    )
};

export default WalletAddress;
