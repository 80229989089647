import React, {useEffect, useState} from 'react';
import YearsGrid from "../../molecules/YearsGrid/YearsGrid";
import "./Biographic.scss";

import BioCard from "../../molecules/BioCard/BioCard";
import FilterPills from "../../molecules/FilterPills/FilterPills";
import painterEventsDataUtil from "../../../utils/painterEventsDataUtil";
import paintersDataUtil from "../../../utils/paintersDataUtil";
import Artwork from "../../molecules/Artwork/Artwork";
import urlUtil from "../../../utils/urlUtil";
import generalUtil from "../../../utils/generalUtil";
import browserInfoUtil from "../../../utils/browserInfoUtil";
import {Helmet} from "react-helmet";
import metaUtil from "../../../utils/metaUtil";
import Page from "../../atoms/Page/Page";
import {Link} from "react-router-dom";
import bioTransitionsUtil from "./bioTransitionsUtil";
import {paramCase} from "change-case";
import TopNav from "../../molecules/TopNav/TopNav";

function Biographic(props) {
    const [scrollTop, setScrollTop] = useState(0);
    const isExitingPage = !urlUtil.getCurrentURL().includes('/bio')
    const { slug, year, filter, artwork } = isExitingPage ? urlUtil.getURLParams(generalUtil.getExplorationPath()[0]) : urlUtil.getURLParams()

    const eventsData = painterEventsDataUtil.getForBioBySlug(slug)
    const personData = paintersDataUtil.getBySlug(slug)
    const isIntroOpen = filter === "Intro"

    // set grid height so it can scroll based on card height
    // scroll bio year into view
    useEffect(() => {
        const gridEl = document.getElementById("years-grid")
        const cardEl = document.getElementById("bio-card")

        if (!gridEl) return

        let isDirectLink = true
        const prevRoute = generalUtil.getExplorationPath()[0]
        if (prevRoute) {
            isDirectLink = !(prevRoute.href.includes('/bio') && prevRoute.href.includes(slug))
        }

        gridEl.style.paddingBottom = (filter || year) ? `243px` : '0px';

        let scrollYear = year
        if (filter && !year && !isIntroOpen) {
            scrollYear = BioData.eventsByYear.find(y => !!y.events.find(e => e.category === filter)).year
            document.querySelector(`#${ paramCase(filter) }`).scrollIntoView()
        }

        if (scrollYear) {
            const row = Math.ceil((personData.birthYear % 5 + Number(scrollYear) - personData.birthYear + 1) / 5) - 2
            const pageEl = document.querySelector(".bio-page")
            const yearScrollTop = row * 50
            setScrollTop(yearScrollTop)
            pageEl.scrollTo({
                top: yearScrollTop,
                behavior: isDirectLink ? 'auto' : 'smooth',
            })
        }

        gridEl.style.paddingBottom = (filter || year) ? `243px` : '0px';
    }, [year, filter])

    useEffect(() => {
        setScrollTop(0)
    }, [slug])

    const BioData = {
        ...personData,
        eventsByYear: eventsData
    }
    let { name, birthYear, deathYear } = BioData

    const gridData = painterEventsDataUtil.parseYearGridData(BioData.categories, eventsData)
    const filterOptions = Object.keys(BioData.categories).map(cat => {
        if (cat) {
            return {
                option: cat,
                color: BioData.categories[cat],
                route: urlUtil.createURL('bio', { slug, filter: cat }),
                activeFilter: filter,
            }
        }
    })

    const nationalityLinks = []
    BioData.nationality.forEach((n, i) => {
        if (i !== 0) {
            nationalityLinks.push(<span key={i}>  |  </span>)
        }
        nationalityLinks.push(
            <Link
                key={n}
                to={urlUtil.createURL('timeline', { type: 'art', filter: n, scroll: name }, { keepOthers: true })}
            >
                {n}
            </Link>
        )
    })
    const movementLinks = []
    BioData.movements.forEach((m, i) => {
        if (i !== 0) {
            movementLinks.push(<span key={i}>  |  </span>)
        }
        movementLinks.push(
            <Link
                key={m}
                to={urlUtil.createURL('timeline', { type: 'art', filter: m, scroll: name }, { keepOthers: true })}
            >
                {m}
            </Link>
        )
    })

    const metaInfo = !isExitingPage ? metaUtil.getMetaForBio() : {}

    const navSubtitle = isIntroOpen ? "Show less..." : "Show more..."
    const navSubtitleLink = isIntroOpen
        ? urlUtil.createURL("bio", { slug, filter: null })
        : urlUtil.createURL("bio", { slug, filter: "Intro" })

    return (
        <Page
            classes={`bio-page ${slug}`}
            id="bio-page"
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ metaInfo.title }</title>
                <meta name="description" content={  metaInfo.description } />
                <meta name="keywords" content={ metaInfo.keywords } />
                <meta name="og:image" content={ metaInfo.image } />
            </Helmet>

            <TopNav
                header={name}
                // subtitle={`${birthYear} - ${ BioData.isAlive ? "" : deathYear }`}
                subtitle={navSubtitle}
                subtitleLink={navSubtitleLink}
                slug={slug}
            />

            <div style={{ height: 64 }}>
                <FilterPills
                    options={ filterOptions }
                    baseRoute={ urlUtil.createURL("bio", { slug }) }
                    fixed
                />
            </div>

            <YearsGrid
                birth={birthYear}
                death={deathYear}
                data={gridData}
                step={browserInfoUtil.isDesktop() ? 10 : 5}
            />

            <div className="mobile-card only-mobile">
                <BioCard
                    gridData={gridData}
                    bioData={BioData}
                />
            </div>

            <Artwork />
        </Page>
    );
}

export default generalUtil.withNavParams(Biographic)