import React, { Component } from 'react';
import "./FilterPills.scss"

import FilterPill from "../../atoms/FilterPill/FilterPill";
import generalUtil from "../../../utils/generalUtil";

class FilterPills extends Component {
    render () {
        const { options, baseRoute, fixed } = this.props
        if (!options) return
        const pills = options.map(o => (
            <FilterPill
                key={o.color}
                color={ o.color }
                route={ o.route }
                activeFilter={ o.activeFilter }
                noCircle
                baseRoute={baseRoute}
            >
                { o.option }
            </FilterPill>
        ))

        const pillsClasses = [
            "filter-pills",
            fixed ? "fixed" : "static"
        ].join(" ")
        const wrapperClasses = ["pills-wrapper"]
        if (pills.length > 4) {
            wrapperClasses.push("scrollable")
        }

        return (
            <div className={ pillsClasses }>
                <div className={ wrapperClasses.join(" ") }>
                    { pills }
                </div>
            </div>
        )
    }
}

FilterPills.propTypes = {
};

export default generalUtil.withNavParams(FilterPills)