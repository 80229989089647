import urlUtil from "./urlUtil";
import episodesDataUtil from "./episodesDataUtil";

const RANDOM_LINKS = [
    { epId: "s01e01p00", caption: 'Who was Paja Jovanovic?', url: urlUtil.createURL('timeline', { type: 'painters', scroll: 'Paja Jovanovic', bubble: 'paja-jovanovic' })},
    { epId: "s01e01p00", caption: 'Who are the greatest impressionists?', url: urlUtil.createURL('timeline', { type: 'painters', filter: 'Impressionism', scroll: 'Claude Monet' })},
    { epId: "s01e01p00", caption: 'Who are the greatest Japanese artists?', url: urlUtil.createURL('timeline', { type: 'painters', filter: 'Japanese', scroll: 'Katsushika Hokusai' })},
    { epId: "s01e01p00", caption: 'Who are the greatest Pop Art artists?', url: urlUtil.createURL('timeline', { type: 'painters', filter: 'Pop Art', scroll: 'Andy Warhol' })},

    { epId: "s01e01p01", caption: 'How turbulent was Frida Kahlo\'s love life?', url: urlUtil.createURL('bio', { slug: 'frida-kahlo', filter: 'Love' })},
    { epId: "s01e01p01", caption: 'How Frida Kahlo\'s health issues influenced her art?', url: urlUtil.createURL('bio', { slug: 'frida-kahlo', filter: 'Health' })},

    { epId: "s01e01p02", caption: 'How many kids did Gustav Klimt have?', url: urlUtil.createURL('bio', { slug: 'gustav-klimt', filter: 'Children' })},
    { epId: "s01e01p02", caption: 'What is Gustav Klimt\'s most famous painting?', url: urlUtil.createURL('bio', { slug: 'gustav-klimt', year: 1908, artwork: 'the-kiss' })},

    { epId: "s01e02p01", caption: 'In what fields did Leonardo da Vinci excel during his lifetime?', url: urlUtil.createURL('bio', { slug: 'leonardo-da-vinci', filter: 'Polymath' })},
    { epId: "s01e02p01", caption: 'How many paintings di Leonardo da Vinci create?', url: urlUtil.createURL('bio', { slug: 'leonardo-da-vinci', filter: 'Paintings' })},

    { epId: "s01e02p02", caption: 'What was Michelangelo\'s most grandiose work of art?', url: urlUtil.createURL('bio', { slug: 'michelangelo', filter: 'Sistine Chapel' })},
    { epId: "s01e02p02", caption: 'What was Michelangelo\'s architecture career like?', url: urlUtil.createURL('bio', { slug: 'michelangelo', filter: 'Architecture' })},

    { epId: "s01e03p01", caption: 'What is the most famous Triptych of Hieronymus Bosch?', url: urlUtil.createURL('bio', { slug: 'hieronymus-bosch', artwork: "the-garden-of-earthly-delights", year: 1501 })},
    { epId: "s01e03p01", caption: 'Which 16th century artist painted nightmarish and fantastical imagery?', url: urlUtil.createURL('bio', { slug: 'hieronymus-bosch', filter: 'Paintings' })},

    { epId: "s01e03p02", caption: 'Which artist had a prolific career in diplomacy?', url: urlUtil.createURL('bio', { slug: 'peter-paul-rubens', filter: 'Diplomacy' })},
    // { epId: "s01e03p02", caption: 'Rubens', url: urlUtil.createURL('bio', { slug: 'peter-paul-rubens', filter: '' })},

    { epId: "s01e04p01", caption: 'How many self-portraits did Rembrandt make?', url: urlUtil.createURL('bio', { slug: 'rembrandt-van-rijn', filter: 'Self-portraits' })},
    { epId: "s01e04p01", caption: 'What is Rembrandt\'s most famous painting?', url: urlUtil.createURL('bio', { slug: 'rembrandt-van-rijn', artwork: 'the-nightwatch', year: 1642 })},

    { epId: "s01e04p02", caption: 'Which artist created only 34 known paintings?', url: urlUtil.createURL('bio', { slug: 'johannes-vermeer', filter: 'Paintings' })},
    { epId: "s01e04p02", caption: 'How many kids did Johannes Vermeer have?', url: urlUtil.createURL('bio', { slug: 'johannes-vermeer', filter: 'Children' })},

    { epId: "s01e05p01", caption: 'Which artist is known for killing a man?', url: urlUtil.createURL('bio', { slug: 'caravaggio', year: 1606 })},
    { epId: "s01e05p01", caption: 'How turbulent was Caravaggio\'s relationship with the law?', url: urlUtil.createURL('bio', { slug: 'caravaggio', filter: 'Crimes' })},

    { epId: "s01e05p02", caption: 'Who painted the haunting and mysterious Black Paintings on the walls of his house?', url: urlUtil.createURL('bio', { slug: 'francisco-goya', filter: 'Black Paintings' })},
    // { epId: "s01e05p02", caption: 'Goya', url: urlUtil.createURL('bio', { slug: 'francisco-goya', filter: '' })},

    // { epId: "s01e06p01", caption: 'Hokusai', url: urlUtil.createURL('bio', { slug: 'katsushika-hokusai', filter: '' })},
    // { epId: "s01e06p01", caption: 'Hokusai', url: urlUtil.createURL('bio', { slug: 'katsushika-hokusai', filter: '' })},

    // { epId: "s01e06p02", caption: 'Hiroshige', url: urlUtil.createURL('bio', { slug: 'utagawa-hiroshige', filter: '' })},
    // { epId: "s01e06p02", caption: 'Hiroshige', url: urlUtil.createURL('bio', { slug: 'utagawa-hiroshige', filter: '' })},

    // { epId: "s01e07p01", caption: 'Monet', url: urlUtil.createURL('bio', { slug: 'claude-monet', filter: '' })},
    // { epId: "s01e07p01", caption: 'Monet', url: urlUtil.createURL('bio', { slug: 'claude-monet', filter: '' })},

    // { epId: "s01e07p02", caption: 'Cezanne', url: urlUtil.createURL('bio', { slug: 'paul-cezanne', filter: '' })},
    // { epId: "s01e07p02", caption: 'Cezanne', url: urlUtil.createURL('bio', { slug: 'paul-cezanne', filter: '' })},

    // { epId: "s01e08p01", caption: 'Munch', url: urlUtil.createURL('bio', { slug: 'edvard-munch', filter: '' })},
    // { epId: "s01e08p01", caption: 'Munch', url: urlUtil.createURL('bio', { slug: 'edvard-munch', filter: '' })},

    // { epId: "s01e08p02", caption: 'Vincent', url: urlUtil.createURL('bio', { slug: 'vincent-van-gogh', filter: '' })},
    // { epId: "s01e08p02", caption: 'Vincent', url: urlUtil.createURL('bio', { slug: 'vincent-van-gogh', filter: '' })},

    { epId: "", caption: 'What was van Gogh doing before he became a painter?', url: urlUtil.createURL('bio', { slug: 'vincent-van-gogh', filter: 'Professional' })},

    { epId: "", caption: 'What was van Gogh doing before he became a painter?', url: urlUtil.createURL('bio', { slug: 'vincent-van-gogh', filter: 'Professional' })},

    { epId: "", caption: 'How many self-portraits did Pablo Picasso make?', url: urlUtil.createURL('bio', { slug: 'pablo-picasso', filter: 'Self-portraits' })},
    { epId: "", caption: 'What was Kandinsky\'s other passion?', url: urlUtil.createURL('bio', { slug: 'wassily-kandinsky', filter: 'Music' })},
]

const randomQuestionsUtil = {
    getQuestions: (number = 9) => {
        const epIds = episodesDataUtil.getReleasedEpisodeIds()
        return RANDOM_LINKS
            .filter(rl => epIds.includes(rl.epId))
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
            .slice(0, number)
    }
}

export default randomQuestionsUtil