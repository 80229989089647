import urlUtil from "./urlUtil";
import {camelCase, capitalCase, noCase} from "change-case";
import {ENVS} from "../constants";
const OG_IMAGES = {
    "curiosity-monster": require("../assets/images/og/og-curiosity-monster.jpg"),
    "art-history": require("../assets/images/og/og-art-history.jpg"),

    "frida-kahlo": require("../assets/images/og/og-frida-kahlo.jpg"),
    "gustav-klimt": require("../assets/images/og/og-gustav-klimt.jpg"),
    "leonardo-da-vinci": require("../assets/images/og/og-leonardo-da-vinci.jpg"),
    "michelangelo": require("../assets/images/og/og-michelangelo.jpg"),
    "hieronymus-bosch": require("../assets/images/og/og-hieronymus-bosch.jpg"),
    "peter-paul-rubens": require("../assets/images/og/og-peter-paul-rubens.jpg"),
    "rembrandt-van-rijn": require("../assets/images/og/og-rembrandt-van-rijn.jpg"),
    "johannes-vermeer": require("../assets/images/og/og-johannes-vermeer.jpg"),
    "caravaggio": require("../assets/images/og/og-caravaggio.jpg"),
    "francisco-goya": require("../assets/images/og/og-francisco-goya.jpg"),
    "katsushika-hokusai": require("../assets/images/og/og-katsushika-hokusai.jpg"),
    "utagawa-hiroshige": require("../assets/images/og/og-utagawa-hiroshige.jpg"),
    "claude-monet": require("../assets/images/og/og-claude-monet.jpg"),
    "paul-cezanne": require("../assets/images/og/og-paul-cezanne.jpg"),
    "edvard-munch": require("../assets/images/og/og-edvard-munch.jpg"),
    "vincent-van-gogh": require("../assets/images/og/og-vincent-van-gogh.jpg"),
}

const isProduction = true || process.env === ENVS.PRODUCTION

const metaUtil = {
    getIndexKeywords: () => {
        return [
            "curiosity monster",
            "interactive infographics",
            "interactive visualizations",
            "visual encyclopedia",
            "mobile-friendly infographics",
            "data visualization",
            "interactive learning",
        ]
    },

    getMetaForIndex: () => {
        return {
            title: "Curiosity Monster",
            description: "Embark on serendipitous adventures through stunning, engaging and interactive visualizations with Curiosity Monster.",
            image: OG_IMAGES["curiosity-monster"],
            keywords: isProduction ? metaUtil.getIndexKeywords().join(",") : "",
        }
    },

    getQueryKeywords: () => {
        return Object.values(urlUtil.getURLParams()).map(k => noCase(k))
    },

    getTimelineKeywords: () => {
        return [
            ...metaUtil.getIndexKeywords(),
            "art history timeline",
            "art history",
            "art movements",
            "evolution of art",
        ]
    },

    getMetaForTimeline: () => {
        return {
            title: "Art History Timeline",
            description: "Unleash your inner art nerd and discover different masterpieces, artists, and art movements on our Art History timeline.",
            image: OG_IMAGES["art-history"],
            keywords: isProduction ? metaUtil.getTimelineKeywords().join(",") : "",
        }
    },

    getBioKeywords: (name) => {
        return [
            ...metaUtil.getIndexKeywords(),
            "chronological biography",
            "biography visualization",
            "biography infographic",
            "biography timeline",
            "personal life",
            "art career",
            "paintings",
            "artistic journey",
            "timeline",
            name,
            `${name} biography`,
        ]
    },

    getMetaForBio: () => {
        const slug = urlUtil.getURLParams().slug
        const name = capitalCase(slug)
        return {
            title: name,
            description: `Dive into ${name}'s personal life, wild career, and over 50 jaw-dropping masterpieces on Curiosity Monster.`,
            image: OG_IMAGES[slug],
            keywords: isProduction ? metaUtil.getBioKeywords(name).join(",") : "",
        }
    }
}

export default metaUtil