import React from 'react';
import "./Artwork.scss"
import {Link} from "react-router-dom";
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";
import {capitalCase} from "change-case";
import artworksDataUtil from "../../../utils/artworksDataUtil";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import urlUtil from "../../../utils/urlUtil";
import {AnimatePresence, motion} from "framer-motion";
import Text from "../../atoms/Text/Text";

function Artwork () {
    const { artwork } = urlUtil.getURLParams()
    const artworkData = artwork ? artworksDataUtil.getBySlug(artwork) : ''
    const closeRoute = artwork ? urlUtil.createURL('bio', { artwork: undefined }, { keepOthers: true }) : ''

    return (
        <AnimatePresence>
            { artwork && (
                <motion.div
                    className="artwork"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: "easeInOut", duration: 0.3 }}
                >
                    <TransformWrapper disablePadding>
                        <TransformComponent id="zoom">
                            <img src={ artworkData.imageLink } id="artwork-image" className="artwork-image" alt={artworkData.name} />
                        </TransformComponent>
                    </TransformWrapper>
                    <div className="close-btn">
                        <Link to={closeRoute}>
                            <SVGIcon
                                size={32}
                                name="close"
                                color="link"
                            />
                        </Link>
                    </div>

                    <div className="copyright-text">
                        <SVGIcon
                            size={24}
                            name={ artworkData.copyrightIcon }
                        />
                        <Text>{ artworkData.copyrightText }</Text>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

Artwork.propTypes = {
};

export default Artwork