import {motion} from "framer-motion";
import React from "react";

import "./Page.scss"
import BigScreenDisclaimer from "../BigScreenDisclaimer/BigScreenDisclaimer";

const Page = (props) => {
    const { classes, id, scrollable = true, onScroll, style } = props
    const pageClasses = [
        "page",
        classes,
        scrollable ? "scrollable" : "fixed",
    ].join(" ")

    return (
        <motion.div
            style={style}
            onScroll={onScroll}
            key={classes}
            className={pageClasses}
            id={id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.3 }}
        >
            <BigScreenDisclaimer />

            <div className="page-content">
                { props.children }
            </div>
        </motion.div>
    );
};

export default Page;
