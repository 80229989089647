import DB from "../data/DB.json"
import urlUtil from "./urlUtil";
import {MONSTERS} from "./monsterImagesUtil";
import {camelCase} from "change-case";

const {REACT_APP_ENV} = process.env
const episodesJSON = DB.Episodes
let episodesData

const episodesDataUtil = {
    getAll: () => {
        episodesDataUtil.parseData()
        return episodesData
    },

    getNewestEpisodes: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isNewest && ep.pID)
    },

    getReleasedForSeason: (season = "Art") => {
        return episodesDataUtil.getAll().filter(ep => (ep.season.toLowerCase() === season.toLowerCase() || ep.sID == season) && ep.isReleased)
    },

    isEpReleased: (slug) => {
        return !!episodesDataUtil.getReleasedForSeason().find(ep => ep.param === slug)
    },

    getReleasedEpisodeIds: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isReleased).map(ep => ep.id)
    },

    getReleasedEpisodeSlugs: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isReleased).map(ep => ep.slug)
    },

    getNextEpisodes: () => {
        return episodesDataUtil.getAll().filter(ep => ep.isNext)
    },

    parseData: () => {
        if (episodesData) return;
        let newestEp
        let nextEp

        episodesData = episodesJSON
            .filter(e => e.id && e.name)
            .sort((a,b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()))
            .map(e => {
                const ep = { ...e }

                ep.url = urlUtil.createURL(ep.page, { slug: ep.param })
                if (ep.page === "timeline") {
                    ep.url = urlUtil.createURL(ep.page, { type: ep.param })
                }

                ep.description = ep.desc && Array.isArray(ep.desc) ? ep.desc.join(", ") : ep.desc
                ep.imageLink = MONSTERS[ep.imageKey || camelCase(ep.name)]


                ep.rDate = REACT_APP_ENV && REACT_APP_ENV === "PRODUCTION" ? new Date(ep.rDateProd) : new Date(ep.rDateStag)
                ep.isReleased = ep.rDate && ep.rDate.getTime() < new Date().getTime()

                if (ep.isReleased) {
                    newestEp = ep.id.slice(0, 6)
                } else if (!nextEp) {
                    nextEp = ep.id.slice(0, 6)
                }

                return ep
            }).map(e => {
                const ep = {
                    ...e,
                    isNext: e.id.includes(nextEp),
                    isNewest: e.id.includes(newestEp),
                }

                return ep
            })
    }
}

export default episodesDataUtil