import React, { useEffect } from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import {BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Timeline from "./components/pages/Timeline/Timeline";
import Biographic from "./components/pages/Biographic/Biographic";
import Home from "./components/pages/Home/Home";
import mixpanel from 'mixpanel-browser';

import "./index.scss"
import ThemeWrapper from "./components/atoms/ThemeWrapper/ThemeWrapper";
import generalUtil from "./utils/generalUtil";
import urlUtil from "./utils/urlUtil";
import {ReactFlowProvider} from "reactflow";
import {ENVS} from "./constants";
import episodesDataUtil from "./utils/episodesDataUtil";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
const routeChangeListener = setInterval(generalUtil.explorationPathInterval, 1000)

const bodyEl = document.getElementsByTagName('body')[0]
if (window.location.origin.includes("http://localhost:45678")) {
    bodyEl.classList = `react-snap`
}

function ScrollToTop() {
    const { pathname } = useLocation();
    const { slug } = urlUtil.getURLParams(useLocation())

    useEffect(() => {
        const pageEls = document.getElementsByClassName("page")
        const activePage = pageEls[pageEls.length - 1]

        if (activePage) {
            activePage.scrollTo({ top: 0 })
        }
    }, [pathname, slug]);

    return null;
}

const location = window.location;
const isComingSoon = process.env.REACT_APP_ENV === ENVS.PRODUCTION && !episodesDataUtil.getReleasedForSeason().length

const App = (
    <React.StrictMode>
        <ReactFlowProvider>
            <ThemeWrapper>
                <BrowserRouter>
                    <ScrollToTop />
                    <Routes location={location} key={location.pathname}>
                        { !isComingSoon && <Route path="/timeline/:type" element={<Timeline />} /> }
                        { !isComingSoon && <Route path="/bio/:slug" element={<Biographic />} /> }
                        <Route index path="*" element={<Home />} />
                    </Routes>
                </BrowserRouter>
            </ThemeWrapper>
        </ReactFlowProvider>
    </React.StrictMode>
)
const rootEl = document.getElementById('root')

if (rootEl.hasChildNodes()) {
    hydrateRoot(document.getElementById('root'), App);
} else {
    createRoot(rootEl).render(App)
}
