import React, { Component } from 'react';
import "./Text.scss"

class Text extends Component {
    static defaultProps = {
        type: 'p',
    }

    render () {
        const { type, children, ellipsis, alt, align, font, style, weight, className, span, uppercase } = this.props

        const classes = [
            'text',
            type,
            ellipsis ? 'ellipsis' : '',
            alt ? 'alt' : '',
            align ? `align-${align}` : '',
            font ? 'secondary' : '',
            uppercase ? 'uppercase' : '',
            className,
        ].join(' ')

        const textStyle = {
            ...style,
            fontWeight: weight,
        }

        const TextElement = span ? "span" : "p"

        return (
            <TextElement
                className={classes}
                style={textStyle}
            >
                {children}
            </TextElement>
        )
    }
}

Text.propTypes = {
};

export default Text