import urlUtil from "./utils/urlUtil";

export const ENVS = {
    PRODUCTION: "PRODUCTION",
    STAGING: "STAGING"
}

export const THEMES = {
    LIGHT: 'light',
    DARK: 'dark',
    JUNGLE: 'jungle',
    BALEARIC: 'balearic',
    RAVE: 'rave',
    HIPPIE: 'hippie',
    PASTEL: 'pastel',
}

export const EVENT_LINK_TYPES = {
    ARTWORK: 'artwork',
    BIO: 'bio',
}

export const TIMELINE_FILTER_SCROLLS = {
    // nationalities
    ['American']: { scroll: 'Andy Warhol', type: 'nationality' },
    ['British']: { scroll: 'Banksy', type: 'nationality' },
    ['Chinese']: { scroll: 'Ai Weiwei', type: 'nationality' },
    ['German']: { scroll: 'Albrecht Dürer', type: 'nationality' },
    ['French']: { scroll: 'Claude Monet', type: 'nationality' },
    ['Italian']: { scroll: 'Leonardo da Vinci', type: 'nationality' },
    ['Serbian']: { scroll: 'Marina Abramovic', type: 'nationality' },
    ['Dutch']: { scroll: 'Rembrandt van Rijn', type: 'nationality' },
    ['Spanish']: { scroll: 'Pablo Picasso', type: 'nationality' },
    ['Japanese']: { scroll: 'Katsushika Hokusai', type: 'nationality' },
    ['Russian']: { scroll: 'Wassily Kandinsky', type: 'nationality' },

    // movements
    ['Impressionism']: { scroll: 'Claude Monet', type: 'movement' },
    ['Baroque']: { scroll: 'Johannes Vermeer', type: 'movement' },
    ['Pop Art']: { scroll: 'Andy Warhol', type: 'movement' },
    ['Cubism']: { scroll: 'Pablo Picasso', type: 'movement' },
    ['Expressionism']: { scroll: 'Egon Schiele', type: 'movement' },
    ['Dada']: { scroll: 'Marcel Duchamp', type: 'movement' },
    ['Early Renaissance']: { scroll: 'Sandro Botticelli', type: 'movement' },
    ['High Renaissance']: { scroll: 'Leonardo da Vinci', type: 'movement' },
    ['Post-Impressionism']: { scroll: 'Paul Cezanne', type: 'movement' },
    // ['Modernism']: { scroll: '', type: 'movement, }
    ['Realism']: { scroll: 'Édouard Manet', type: 'movement' },
    ['Northern Renaissance']: { scroll: 'Hieronymus Bosch', type: 'movement' },
    ['Surrealism']: { scroll: 'Salvador Dali', type: 'movement' },
    ['Romanticism']: { scroll: 'Francisco Goya', type: 'movement' },
    ['Edo Period (1603–1867)']: { scroll: 'Katsushika Hokusai', type: 'movement' },

    // gender
    ['Male']: { scroll: 'Caravaggio', type: 'other' },
    ['Female']: { scroll: 'Frida Kahlo', type: 'other' },
    ['Alive']: { scroll: 'Banksy', type: 'other' },
}

export const ISO2 = {
    American: 'us',
    Argentinean: 'ar',
    Austrian: 'at',
    Belarusian: 'by',
    Belgian: 'be',
    Brazilian: 'br',
    British: 'gb',
    Canadian: 'ca',
    Chinese: 'cn',
    Colombian: 'co',
    Cuban: 'cu',
    Czech: 'cz',
    Danish: 'dk',
    Dutch: 'nl',
    French: 'fr',
    German: 'de',
    Greek: 'gr',
    Hungarian: 'hu',
    Indian: 'in',
    Irish: 'ie',
    Italian: 'it',
    Japanese: 'jp',
    Korean: 'kr',
    Latvian: 'lv',
    Mexican: 'mx',
    Norwegian: 'no',
    Polish: 'pl',
    Portugese: 'pt',
    Qatari: 'qa',
    Romanian: 'ro',
    Russian: 'ru',
    Serbian: 'rs',
    Spanish: 'es',
    Swedish: 'se',
    Swiss: 'ch',
    Ukrainian: 'ua',
}

export const EVENTS = {
    NAVIGATE: "NAVIGATE",
}

export const WALLETS = {
    BTC: {
        coin: "Bitcoin",
        wallet: "38Jk3469aeiu5FjwwaTR3gz2ydjEik1nEG",
    },
    ETH: {
        coin: "Ethereum",
        wallet: "0x95c8a5f473A472b2B74cBf81e4366780DB1089dB"
    },
    MATIC: {
        coin: "Polygon",
        wallet: "0x95c8a5f473A472b2B74cBf81e4366780DB1089dB"
    },
}