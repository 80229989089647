import { detect } from "detect-browser";

const browserInfo = detect()

const SIZES = {
    MOBILE: 440,
    DESKTOP: 441,
}

const browserInfoUtil = {
    getBrowserInfo: () => browserInfo,
    getWindowSize: () => ({
        width: window.innerWidth,
        height: window.innerHeight,
        orientation: window.screen.orientation,
    }),
    isDesktop: () => browserInfoUtil.getWindowSize().width > SIZES.DESKTOP,
    isMobile: () => browserInfoUtil.getWindowSize().width < SIZES.MOBILE,
    getScreen: () => {
        if (browserInfoUtil.isMobile()) return "mobile"
        if (browserInfoUtil.isDesktop()) return "desktop"
    }
}

export default browserInfoUtil