import React from "react";
import "./MonsterCircle.scss"

const MonsterCircle = (props) => {
    const { src, size, color } = props
    const style = {
        width: size || 100,
        height: size || 100,
    }

    const classes = [
        "monster-circle",
        color
    ].join(" ")

    return (
        <div className={classes} style={style}>
            <div className="circle" />
            <img src={src} alt="" className="monster"/>
        </div>
    )
};

export default MonsterCircle;
