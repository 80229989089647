import React, { Component } from 'react';
import "./SVGIcon.scss"

import {ReactComponent as BitcoinIcon} from "../../../assets/svgs/btc.svg"
import {ReactComponent as EthereumIcon} from "../../../assets/svgs/eth.svg"
import {ReactComponent as PolygonIcon} from "../../../assets/svgs/matic.svg"
import {ReactComponent as CopyrightIcon} from "../../../assets/svgs/copyright.svg"
import {ReactComponent as FairUseIcon} from "../../../assets/svgs/fair-use.svg"
import {ReactComponent as PublicDomainIcon} from "../../../assets/svgs/public-domain.svg"


import {
    HomeIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MagnifyingGlassPlusIcon,
    MagnifyingGlassMinusIcon,
    MagnifyingGlassIcon,
    FlagIcon,
    Bars3Icon,
    PaintBrushIcon,
    XMarkIcon,
    ShareIcon,
    PhotoIcon,
    AdjustmentsHorizontalIcon,
    BellAlertIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    CalendarDaysIcon,
    HeartIcon,
    DocumentDuplicateIcon,
    SunIcon,
    MoonIcon,
    CheckCircleIcon,
} from '@heroicons/react/24/solid'

import { pascalCase } from "change-case";

const icons = {
    ChevronRight: ChevronRightIcon,
    ChevronLeft: ChevronLeftIcon,
    Hamburger: Bars3Icon,
    Close: XMarkIcon,
    Artwork: PhotoIcon,
    Search: MagnifyingGlassIcon,
    Filter: AdjustmentsHorizontalIcon,
    ZoomIn: MagnifyingGlassPlusIcon,
    ZoomOut: MagnifyingGlassMinusIcon,
    Home: HomeIcon,
    Flag: FlagIcon,
    PaintBrush: PaintBrushIcon,
    Share: ShareIcon,
    BellAlert: BellAlertIcon,
    ArrowLeft: ArrowLeftIcon,
    ArrowRight: ArrowRightIcon,
    CalendarDays: CalendarDaysIcon,
    Heart: HeartIcon,
    DocumentDuplicate: DocumentDuplicateIcon,
    Sun: SunIcon,
    Moon: MoonIcon,
    CheckCircle: CheckCircleIcon,

    Bitcoin: BitcoinIcon,
    Ethereum: EthereumIcon,
    Polygon: PolygonIcon,
    Copyright: CopyrightIcon,
    FairUse: FairUseIcon,
    PublicDomain: PublicDomainIcon,
}


class SVGIcon extends Component {
    render () {
        const { name, alt, size, color, hex } = this.props
        const Icon = icons[pascalCase(name)]

        const svgClasses = [
            'svg',
            alt ? 'alt' : '',
            color ? `clr-${color}` : '',
            name,
        ].join(' ')

        let style = {}
        if (size) {
            style = { ...style, width: size, height: size }
        }
        if (hex) {
            style = { ...style, width: size, height: size, fill: hex }
        }

        const classes = [
            "svg-icon",
            name,
        ].join(" ")

        return (
            <div className={classes} style={style}>
                {/*<Icon className="h-6 w-6 text-blue-500" />*/}
                <Icon className={svgClasses} style={style}/>
            </div>
        )
    }
}

SVGIcon.propTypes = {
};

export default SVGIcon