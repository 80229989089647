import {useNavigate, useParams, useLocation} from "react-router-dom";
import React from "react";
import urlUtil from "./urlUtil";
import {capitalCase} from "change-case";
import {EVENTS} from "../constants";
import mixpanelUtil from "./mixpanelUtil";

function createSessionId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const sessionId = createSessionId(24)
const explorationPath = []

const generalUtil = {
    withNavParams: (Component) => {
        return props => <Component {...props} location={useLocation()} params={useParams()} navigateTo={useNavigate()} />;
    },

    explorationPathInterval: () => {
        const location = window.location
        if (!explorationPath[0] || location.href !== explorationPath[0].href) {
            mixpanelUtil.sendEvent(EVENTS.NAVIGATE, {
                toURL: location.href,
                fromURL: explorationPath[0] && explorationPath[0].href,
                page: capitalCase(location.pathname.slice(1) || "Home"),
                ...urlUtil.getURLParams(),
                explorationPath: explorationPath,
                pageInSession: explorationPath ? explorationPath.length : 0,
                sessionId,
            })
            explorationPath.unshift({ ...location })
        }
    },

    getExplorationPath: () => {
        return [ ...explorationPath]
    },

    isLeavingPage: (url) => {
        const currentPage = window.location
        const nextPage = new URL(`${currentPage.origin}${url}`)
        if (currentPage.pathname !== nextPage.pathname) return true

        if (currentPage.pathname.includes('bio') && nextPage.pathname.includes('bio')) {
            const currentSlug = urlUtil.getURLParams(currentPage).slug
            const nextSlug = urlUtil.getURLParams(nextPage).slug
            return currentSlug !== nextSlug
        }

        return false
    }
}

export default generalUtil