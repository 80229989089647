import React, {useState, useLayoutEffect} from "react";
import "./BigScreenDisclaimer.scss"
import Text from "../Text/Text";
import {MONSTERS} from "../../../utils/monsterImagesUtil";
import browserInfoUtil from "../../../utils/browserInfoUtil";
import {Link} from "react-router-dom";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const BigScreenDisclaimer = () => {

    const [width] = useWindowSize();

    if (width < 576) return
    if (window.location.pathname === "/") return

    return (
        <div className="big-screen-disclaimer">
            <div className="window">
                <img src={MONSTERS.curiosityMonster4} className="hero-image" alt="hero"/>
                <Text align="center" type="h1">Ooops</Text>
                <Text align="center">Curiosity Monster is currently not optimized for wider screens, only for portrait mobile. Our awesome team is working really hard to make this happen soon.</Text>
                <Text align="center">Thank You for your patience!</Text>
                <Link to="/">
                    <Text align="center" type="link">Home</Text>
                </Link>
            </div>
        </div>
    )
};

export default BigScreenDisclaimer;
