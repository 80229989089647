import React, { useState, useEffect } from "react";

import "./TimelineFilter.scss"
import SVGIcon from "../../atoms/SVGIcon/SVGIcon";
import SideMenu from "../SideMenu/SideMenu";
import Text from "../../atoms/Text/Text";
import paintersDataUtil from "../../../utils/paintersDataUtil";
import urlUtil from "../../../utils/urlUtil";
import {Link} from "react-router-dom";
import {TIMELINE_FILTER_SCROLLS} from "../../../constants";

async function share(shareData) {
    try {
        await navigator.share(shareData);
    } catch (err) {
        console.log(err)
    }
}

const TimelineFilter = (props) => {
    const { filter } = urlUtil.getURLParams()
    const [isMenuOpen, setIsMenuOpen] = useState();
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState();
    const [filterText, setFilterText] = useState("");

    const renderSearchItem = ({ item, type }) => {
        const isPainter = item && !!item.slug

        const url = isPainter
            ? urlUtil.createURL('timeline', { type: 'art', scroll: item.name, bubble: item.slug })
            : urlUtil.createURL('timeline', { type: 'art', filter: item, scroll: paintersDataUtil.getScrollToForTimeline(item) })
        const caption = isPainter
            ? item.name
            : item

        return (
            <Link
                key={caption}
                onClick={() => {
                    setIsFilterMenuOpen(false)
                    setFilterText("")
                }}
                to={url}
                className="filter-item"
            >
                <Text>{ caption }</Text>
                <Text type="small" uppercase alt  className={type}>{ type }</Text>
            </Link>
        )
    }

    const movements = paintersDataUtil
        .getAllMovements()
        .filter(m => m && m.toLowerCase().includes(filterText.toLowerCase()))
        .map(m => renderSearchItem({ item: m, type: "movement"}))

    const nationalities = paintersDataUtil
        .getAllNationalities()
        .filter(n => n && n.toLowerCase().includes(filterText.toLowerCase()))
        .map(n => renderSearchItem({ item: n, type: "nationality"}))

    const painters = paintersDataUtil
        .getAll()
        .filter(p => p && p.name.toLowerCase().includes(filterText.toLowerCase()))
        .map(p => renderSearchItem({ item: p, type: "artist"}))

    const other = ['Alive', 'Female', 'Male']
        .filter(f => f && f.toLowerCase().includes(filterText.toLowerCase()))
        .map(f => renderSearchItem({ item: f, type: "other"}))

    const randomFilters = Object.keys(TIMELINE_FILTER_SCROLLS)
        .map(key => ({ filter: key, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(el => el.filter)
        .slice(0, 7)
        .map(key => renderSearchItem({ item: key, type: TIMELINE_FILTER_SCROLLS[key].type }))

    const filteredItems = filterText ? [
        ...movements,
        ...nationalities,
        ...painters,
        ...other,
    ] : randomFilters

    let placeholder
    let placeholderClass = ""
    if (filter && !isFilterMenuOpen) {
        placeholder = filter
        placeholderClass = "filter-active"
    } else if (!filterText && isFilterMenuOpen) {
        placeholder = [
            "Search by Art movement",
            "Search by Nationality",
            "Search by Gender",
            "Find artist",
        ][Math.floor(Math.random() * 4)];
        placeholderClass = "focused"
    } else {
        placeholder = "Explore Art History"
        placeholderClass = "idle"
    }

    filteredItems.sort(function(a, b) {
        var textA = a.key.toUpperCase();
        var textB = b.key.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    const shareData = {
        url: urlUtil.getCurrentURL(),
        text: "Art History Timeline - Curiosity Monster",
        title: "Art History Timeline - Curiosity Monster",
    }

    return (
        <div className="timeline-filter">
            <div className="filter-input-wrapper">
                <div className="side">

                    { filter ? (
                        <div className={`circle ${ filter && "has-filter"}`} />
                    ) : (
                        <SVGIcon name="search" size={24} />
                    )}

                    <input
                        className={`filter-input ${ placeholderClass }`}
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        onFocus={() => setIsFilterMenuOpen(true)}
                        onBlur={() => setTimeout(() => setIsFilterMenuOpen(false),0)}
                        placeholder={placeholder}
                    />
                </div>

                <div className="right side">
                    { filter && (
                        <Link
                            onClick={() => setIsFilterMenuOpen(false)}
                            to={urlUtil.createURL('timeline', { type: 'art' })}
                        >
                            <SVGIcon
                                name="close"
                                size={24}
                                color="link"
                            />
                        </Link>
                    )}

                    <div onClick={async () => await share(shareData)}>
                        <SVGIcon
                            name="share"
                            size={18}
                            color="link"
                        />
                    </div>

                    <div onClick={() => setIsMenuOpen(true)}>
                        <SVGIcon
                            name="hamburger"
                            size={24}
                            color="link"
                        />
                    </div>
                </div>

            </div>

            { isFilterMenuOpen && (
                <div className="filter-menu">
                    { filteredItems.length > 0 ? filteredItems : (
                        <div
                            className="filter-item"
                        >
                            <Text>Oooops... Nothing matches your search.</Text>
                        </div>
                    )}
                </div>
            )}

            <SideMenu
                isMenuOpen={isMenuOpen}
                toggleMenu={() => setIsMenuOpen(false)}
            />
        </div>
    )
};

export default TimelineFilter;
