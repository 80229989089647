import DB from "../data/DB.json"
import {capitalCase} from "change-case";

const painterEventsJSON = DB["Painter Events"]
let painterEventsData

const painterEventsDataUtil = {
    getBySlug: (slug) => {
        painterEventsDataUtil.parseData()
        return painterEventsData.filter(p => p.slug === slug)
    },

    getForBioBySlug: (slug) => {
        const painterEvents = painterEventsDataUtil.getBySlug(slug)
        const uniqueYears = painterEvents
            .map(e => e.year)
            .filter((e, i, arr) => arr.indexOf(e) === i)
            .sort()

        const painterBioEvents = uniqueYears.map(y => {
            const events = painterEvents.filter(e => e.year === y)
            const age = events[0].age
            return { year: y, age, events }
        })

        return painterBioEvents
    },

    getByFilter: (slug, filter) => {
        const bioEvents = painterEventsDataUtil.getForBioBySlug(slug)
        const filterEvents = bioEvents.map(year => {
            const eventsYear = { ...year }
            eventsYear.events = eventsYear.events.filter(e => e.category === capitalCase(filter) || e.category === filter)
            return eventsYear
        }).filter(year => year.events.length > 0)

        return filterEvents
    },

    parseYearGridData: function (categories, eventsByYear) {
        const yearGridData = {}

        eventsByYear.forEach(yd => {
            yearGridData[yd.year] = { cat1: 0, cat2: 0, cat3: 0, cat4: 0, cat5: 0, cat6: 0, cat7: 0 }
            yd.events.forEach(e => yearGridData[yd.year][categories[e.category]]++)
        })

        return yearGridData
    },

    getNetworkedEvent: (year) => {
        return painterEventsData
            .filter(e => e.isNetworked && e.year == year)[0]
    },

    parseData: () => {
        if (painterEventsData) return;
        painterEventsData = painterEventsJSON.filter(e => e.isVerified)
    }
}

export default painterEventsDataUtil