import DB from "../data/DB.json"

const artworksJSON = DB.Artworks
let artworksData

const artworksDataUtil = {
    getCopyrightText: (artwork) => {
        if (!artwork.copyright || artwork.copyright === "Public domain" || artwork.copyright === "Lizenzfrei") return "Public Domain"
        return artwork.copyrightAuthor
    },
    getCopyrightIcon: (artwork) => {
        if (!artwork.copyright || artwork.copyright === "Public domain") return "public-domain"
        return "copyright"
    },

    getBySlug: (slug) => {
        artworksDataUtil.parseData()
        return artworksData.find(p => p.slug === slug)
    },

    parseData: () => {
        if (artworksData) return;
        artworksData = artworksJSON
            .filter(a => a.slug)
            .map(a => ({
                ...a,
                copyrightText: artworksDataUtil.getCopyrightText(a),
                copyrightIcon: artworksDataUtil.getCopyrightIcon(a),
            }))
    }
}

export default artworksDataUtil