import React, { useEffect } from "react";
import "./Timeline.scss";

import { useReactFlow } from "reactflow"
import TimelineCanvas from "../../molecules/TimelineCanvas/TimelineCanvas";
import paintersDataUtil from "../../../utils/paintersDataUtil";
import urlUtil from "../../../utils/urlUtil";
import generalUtil from "../../../utils/generalUtil";
import {Helmet} from "react-helmet";
import metaUtil from "../../../utils/metaUtil";
import Page from "../../atoms/Page/Page";
import TimelineFilter from "../../molecules/TimelineFilter/TimelineFilter";
import timelineUtil from "../../../utils/timelineUtil";

function Timeline() {
    const reactFlowInstance = useReactFlow();
    const isExitingPage = !urlUtil.getCurrentURL().includes('/timeline')
    const { scroll, bubble, filter } = isExitingPage ? urlUtil.getURLParams(generalUtil.getExplorationPath()[0]) : urlUtil.getURLParams()

    useEffect(() => {
        const { viewportInitialized } = reactFlowInstance
        if (!viewportInitialized) return

        if (scroll) {
            const slug = scroll.toLowerCase().replaceAll(" ", "-")
            const node = reactFlowInstance.getNode(slug)
            let zoom = reactFlowInstance.getZoom()
            let position = timelineUtil.calcViewport(node, zoom)

            // when click on a filter
            // then scroll into view to see the artist
            if (filter && !bubble) {
                const timeout = setTimeout(() => {
                    zoom = Math.min(zoom, 1)
                    position = timelineUtil.calcViewport(node, zoom)
                    reactFlowInstance.setViewport(position, { duration: 500 })
                    clearTimeout(timeout)
                }, 500)
                return
            }

            // when click on pill
            // then scroll into view to see the bubble
            if (bubble) {
                zoom = Math.min(Math.max(zoom, 1), 1.5)
                position = timelineUtil.calcViewport(node, zoom)
                position.y = -node.position.y * zoom + 150
                reactFlowInstance.setViewport(position, { duration: 500 })
                return
            }

            reactFlowInstance.setViewport(position, { duration: 600 })
            return
        }
    }, [scroll, bubble, filter, reactFlowInstance.viewportInitialized])

    let timelineData
    timelineData = paintersDataUtil.sortByLVL(paintersDataUtil.getAll())
    if (filter) timelineData = paintersDataUtil.sortBy({ [paintersDataUtil.getFilterType(filter)]: filter })

    const metaInfo = metaUtil.getMetaForTimeline()

    return (
        <Page classes="timeline-page" scrollable={false}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ metaInfo.title }</title>
                <meta name="description" content={  metaInfo.description } />
                <meta name="keywords" content={ metaInfo.keywords } />
                <meta name="og:image" content={ metaInfo.image } />
            </Helmet>

            <TimelineFilter />

            <TimelineCanvas
                start={1325}
                end={2025}
                data={timelineData}
            />
        </Page>
    );
}

export default generalUtil.withNavParams(Timeline)