import React, { useEffect } from 'react';
import "./ThemeWrapper.scss"
import {THEMES} from "../../../constants";
import Div100vh from "react-div-100vh";
import browserInfoUtil from "../../../utils/browserInfoUtil";

const browserInfo = browserInfoUtil.getBrowserInfo()
const screen = browserInfoUtil.getScreen()
const isMobile = browserInfoUtil.isMobile()
const isDesktop = browserInfoUtil.isDesktop()
let windowSize = browserInfoUtil.getWindowSize()

window.addEventListener("resize", () => {
    let windowSize = browserInfoUtil.getWindowSize()
});

const themes = Object.values(THEMES).slice(-5)
const randomTheme = themes[Math.floor(Math.random() * themes.length)]
// const randomTheme = THEMES.PASTEL

function ThemeWrapper(props) {
    useEffect(() => {
        if (!window.location.origin.includes("http://localhost:45678")) {
            const bodyEl = document.getElementsByTagName('body')[0]
            const themeWrapperEl = document.getElementById('theme-wrapper')
            bodyEl.classList = `theme-${randomTheme}`
            themeWrapperEl.classList = `theme theme-${randomTheme}`
        }
    })

    const { children } = props

    return (
        <div className={screen}>
            <div className={`theme theme-dark`} id="theme-wrapper">
                { children }
            </div>
        </div>
    )
}

ThemeWrapper.propTypes = {
};

export default ThemeWrapper