import DB from "../data/DB.json"
import painterDescriptionsJSON from "../data/openai-painter-descriptions.json"
import { camelCase } from "change-case";
import {MONSTERS} from "./monsterImagesUtil";
import {TIMELINE_FILTER_SCROLLS} from "../constants";
import episodesDataUtil from "./episodesDataUtil";

const paintersJSON = DB.Painters
let paintersData
let nationalities = []
let movements = []

const paintersDataUtil = {
    getScrollToForTimeline(filter) {
        return (TIMELINE_FILTER_SCROLLS[filter] && TIMELINE_FILTER_SCROLLS[filter].scroll) || paintersDataUtil.sortBy({ [paintersDataUtil.getFilterType(filter)]: filter })[0].name
    },
    getFilterType: (filterValue) => {
        const nationalities = paintersDataUtil.getAllNationalities()
        const movements = paintersDataUtil.getAllMovements()

        if (nationalities.indexOf(filterValue) >= 0) return 'nationality'
        if (movements.indexOf(filterValue) >= 0) return 'movement'
        if (['Male', 'Female'].indexOf(filterValue) >= 0) return 'gender'
        if (['Alive'].indexOf(filterValue) >= 0) return 'other'
    },

    getAllNationalities: (minEls = 0) => {
        if (nationalities.length) return nationalities

        paintersDataUtil.getAll().forEach(p => p.nationality.forEach(n => nationalities.push(n)))
        nationalities = nationalities
            // filter only with count 3+ and unique
            .filter((n, i, arr) => arr.filter(el => el == n).length > minEls && arr.indexOf(n) === i)
            .sort()
        return nationalities
    },
    getAllMovements: (minEls = 0) => {
        if (movements.length) return movements

        paintersDataUtil.getAll().forEach(p => p.movements.forEach(n => movements.push(n)))
        movements = movements
            // filter only with count minEl+ and unique
            .filter((m, i, arr) => arr.filter(el => el == m).length > minEls && arr.indexOf(m) === i)
            .sort()
        return movements
    },

    getAll: () => {
        paintersDataUtil.parseData()
        return paintersData;
    },

    getBySlug: (slug) => {
        paintersDataUtil.parseData()
        return paintersData.find(p => p.slug === slug)
    },

    parseData: () => {
        if (paintersData) return;
        paintersData = paintersJSON
            .filter(raw => !!raw.birthYear && raw.isValid)
            .map(raw => {
                const parsedData = { ...raw }
                parsedData.deathYear = parsedData.deathYear || new Date().getFullYear()

                parsedData.categories = {}
                for (let i = 1; i <= 7; i++) {
                    let c = `cat${i}`
                    if (raw[c]) parsedData.categories[raw[c]] = c
                    if (raw[`${c}Text`] && Array.isArray(raw[`${c}Text`])) parsedData[`${c}Text`] = parsedData[`${c}Text`].join(', ')
                }

                parsedData.avatar = MONSTERS[camelCase(parsedData.slug)]

                parsedData.nationality = !Array.isArray(parsedData.nationality) ? [parsedData.nationality] : parsedData.nationality
                parsedData.movements = !Array.isArray(parsedData.movements) ? [parsedData.movements] : parsedData.movements


                if (parsedData.shortDescription) {
                    parsedData.shortDescription = Array.isArray(parsedData.shortDescription) ? parsedData.shortDescription.join(", ") : parsedData.shortDescription
                    parsedData.shortDescription = parsedData.shortDescription.replace(/ *\([^)]*\) */g, " ")
                    parsedData.shortDescription = parsedData.shortDescription.replace(/ *\[[^)]*\] */g, " ")
                }

                const openAIShortDesc = painterDescriptionsJSON.find(p => p.slug === parsedData.slug)
                parsedData.intro = openAIShortDesc ? openAIShortDesc.description : parsedData.shortDescription
                return parsedData
            })
    },

    sortByBirthDate: (painters) => painters.sort((a, b) => a.birthYear - b.birthYear),
    sortByLVL: (painters) => {
        const lvlArrays = { hasBio: [], lvl5: [], lvl4: [], lvl3: [], rest: [] };

        painters.forEach(p => {
            if (p.hasBio) lvlArrays.hasBio.push(p)
            else if (p.lvl === 5 && !p.hasBio) lvlArrays.lvl5.push(p)
            else if (p.lvl === 4 && !p.hasBio) lvlArrays.lvl4.push(p)
            else if (p.lvl === 3 && !p.hasBio) lvlArrays.lvl3.push(p)
            else lvlArrays.rest.push(p)
        })

        return [
            ...paintersDataUtil.sortByBirthDate(lvlArrays.hasBio),
            ...paintersDataUtil.sortByBirthDate(lvlArrays.lvl5),
            ...paintersDataUtil.sortByBirthDate(lvlArrays.lvl4),
            ...paintersDataUtil.sortByBirthDate(lvlArrays.lvl3),
            ...paintersDataUtil.sortByBirthDate(lvlArrays.rest),
        ]
    },

    sortBy: (sortParams) => {
        const { movement, nationality, gender, other } = sortParams
        let filteredPainters = []
        let rest = []

        paintersData.forEach(p => {
            if (movement && p.movements.includes(movement)) {
                filteredPainters.push(p)
            } else if (nationality && p.nationality.includes(nationality)) {
                filteredPainters.push(p)
            } else if (gender && p.gender === gender) {
                filteredPainters.push(p)
            } else if (other && other === "Alive" && p.isAlive) {
                filteredPainters.push(p)
            } else {
                rest.push(p)
            }
        })

        filteredPainters = paintersDataUtil.sortByLVL(filteredPainters)
        rest = paintersDataUtil.sortByLVL(rest)

        return [
            ...filteredPainters,
            ...rest
        ]
    }
}

export default paintersDataUtil